import {createApp, reactive} from "petite-vue";
import SearchField from "../components/search-field";
import SearchParameter from "../components/search-parameter";
import {orderQuery} from "../utils/query";
import {addTermSearchCount, savePreviousSearch} from "../utils/search";
import {toggleFavoriteOffer} from "../utils/offer";
import Filter from "./filter";

export default function initArchive(): void {
    const filterForm = document.querySelector('[data-offer-filter]'),
        offerGrid = document.querySelector('[data-offer-grid]')

    if (!filterForm || typeof filterData !== 'object' || typeof archiveData !== 'object') return

    const archive = reactive(Object.assign<FilterStore, ArchiveStore>(Filter(), {
        ...archiveData,
        appendPosts: false,
        scrollIntoView: false,
        isLoading: false,
        lastWatchdogPage: archiveData.page ?? null,
        setQuery(params = {}, resetPager = false, resetQuery = false) {
            const query = params ? {
                ...(resetQuery ? {} : this.query),
                ...params,
                ...(resetPager ? {page: 1} : {})
            } : {}

            this.loadOffers(query)
        },
        submitFilter() {
            this.setQuery({ ...this.selectedFieldsQuery, ...this.selectedParams }, true, true)
        },
        changeOrder() {
            this.setQuery({ order: this.order }, true)
        },
        changePage(page: number) {
            this.scrollIntoView = true
            this.setQuery({ page })
        },
        loadNextPage() {
            this.appendPosts = true
            this.setQuery({ page: this.page + 1 })
        },
        setSearchRefine(parameter, params) {
            const isSingle = filterData?.filterParams[parameter].single ?? false,
                newValue = params[parameter]

            this.selectedParams = {
                ...this.selectedParams,
                [parameter]: isSingle ? newValue ?? "default" : newValue
                    ? Array.isArray(newValue) ? params[parameter] : newValue.split(",") : []
            }

            this.scrollIntoView = true
            this.setQuery(params, true)
        },
        async loadOffers(queryParams = {}) {
            this.isLoading = true

            const query = orderQuery(queryParams, this.queryOrder)
            Object.entries(archive.defaultValues).forEach(([key, value]) => {
                query.get(this.queryOrder[key]) === value && query.delete(this.queryOrder[key]);
            })

            const queryString = [...query].length ? `?${decodeURIComponent(query.toString())}` : '',
                targetUrl = `${location.pathname}${queryString}`

            await fetch(`${this.apiUrl}${queryString}`, {
                method: "GET",
                headers: {
                    "X-WP-Nonce": archiveData.nonce,
                    "X-OnPage-Factor-Getter": "ArchiveOffer"
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    if (this.appendPosts && this.lastWatchdogPage === data.page - 1 && data.nextPageUrl) {
                        data.offers = data.offers.filter((offer: string) => !offer.includes('data-offer-watchdog'))
                    } else {
                        this.lastWatchdogPage = data.page
                    }

                    this.offers = [...(this.appendPosts ? this.offers : []), ...data.offers]
                    this.totalCount = data.totalCount
                    this.order = data.order
                    this.page = data.page
                    this.pager = data.pager
                    this.nextPageUrl = data.nextPageUrl
                    this.query = data.query
                    this.watchdogParameters = data.watchdogParameters
                    this.searchRefine = data.searchRefine

                    if (data.title) {
                        document.querySelector('h1').textContent = data.title
                        document.querySelector('title').innerHTML = `${data.title} ${data.titleAddon ?? ''}`
                    }

                    data.description && document.querySelector('meta[name="description"]')
                        .setAttribute("content", data.description)

                    this.scrollIntoView && !this.appendPosts && offerGrid?.scrollIntoView()

                    if (!queryParams.hasOwnProperty('noHistory')) {
                        history.pushState({}, '', targetUrl)
                        savePreviousSearch(this.selectedInterest, this.selectedLocality, this.selectedParamsTags, targetUrl)
                    }
                })
                .catch(() => location.assign(targetUrl))
                .finally(() => {
                    this.isLoading = this.appendPosts = this.scrollIntoView = false
                    this.selectedInterest.forEach((term: FilterTerm) => addTermSearchCount(term.id))
                })
        },
        scrollToFilter() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }))

    const pager = document.querySelector('[data-pager]')
    pager.querySelector('nav')?.addEventListener('click', e => {
        const target = e.target as HTMLElement
        if (!target.matches('a')) return

        e.preventDefault()
        const pageUrl = new URLSearchParams((new URL(target.getAttribute('href'))).search)
        archive.changePage(parseInt(pageUrl.get(archive.queryOrder.page) ?? "1"))
    })

    createApp({ SearchField, SearchParameter, archive }).mount(filterForm)
    createApp({ archive }).mount(offerGrid)
    createApp({ archive }).mount('[data-search-refine]')

    offerGrid.addEventListener('click', e => {
        const target = e.target as HTMLElement
        target.matches('[data-favorite-offer]') && toggleFavoriteOffer(target)
    })

    offerGrid.addEventListener('submit', async (e: SubmitEvent) => {
        const form = e.target as HTMLFormElement
        e.preventDefault()

        const response = await fetch(`${form.action}`, {
            method: form.method,
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: form.email?.value ?? "",
                parameters: archive.watchdogParameters ?? {}
            })
        })

        const result = await response.json()

        if (!response.ok) {
            form.querySelector('[data-form-alert]').innerHTML = result.output
        } else {
            form.insertAdjacentHTML("afterend", result.output)
            form.remove()
        }
    })
}
